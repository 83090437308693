<!--
 * @Author: your name
 * @Date: 2020-10-16 13:40:33
 * @LastEditTime: 2020-12-17 21:16:49
 * @LastEditors: Please set LastEditors
 * @Description: 发票成功弹框
 * @FilePath: \nethallofficialaccounts\src\views\Invoice\module\invoicePopup.vue
-->
<template>
    <div>
        <van-popup v-model="showFlag" round :closeable="false" :style="{ width: '70%' }" >
            <div class="popup-content" >
                <p>电子发票已申请成功</p>
                <span>开票可能需要约30分钟，请您耐心等待</span>
                <span>如有疑问请联系客服人员。</span>
            </div>
            <div class="closeBtn">
                <van-button type="info" block  @click="closePopup">关闭</van-button>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    name: 'InvoicePopup',
    props: {
        showFlag: {
            type: Boolean,
            require: true,
            default: () => {
                return false;
            }
        }
    },
    data() {
        return {

        }
    },
    methods: {
        closePopup() {
            this.$emit('clickPopup', false)
        }
    }
}
</script>

<style lang="less" scoped>
// 弹出框 
div {
    .popup-content {
        height: 260px;
        background: url('../../../assets/popup-invoice.png') no-repeat 0 0;
        background-size: cover;
        p {
            margin: 0px;
            padding-top: 30px;
            text-align: center;
            color: #ffffff;
            font-size: 18px;
            margin-bottom: 10px;
        }
        span {
            display: block;
            text-align: center;
            color: #ffffff;
            font-size: 12px;
        }
    }
    .closeBtn {
        margin-top: 5px;
        margin-bottom: 20px;
        margin-left: 50px;
        margin-right: 50px;
        border-radius: 8px;
        font-size: 14px;
    }
    /deep/.van-button {
        border-radius: 8px;
    }
}
</style>