<template>
  <div v-if="waterPriceList">
    <div v-if="waterPriceList.wpName" class="cost-header" style="margin-bottom: 0px">
      <div>
        <span class="cost-left-name">{{ waterPriceList.wpName }}</span>
        <!-- <span v-if="waterPriceList.waterQuantity" class="cost-left-data">
          {{ waterPriceList.waterQuantity }}
          <span>吨</span>
        </span> -->
      </div>
    </div>
    <div class="cost" v-for="(item, index) in waterPriceList.detailList" :key="index">
      <div class="cost-content">
        <div class="cct-header">
          <div class="cct-header-name">
            <span class="ccth-name">{{ item.priceItemName }}</span>
            <span class="ccth-describe">{{ item.pdTypeName }}</span>
          </div>
          <div class="cct-header-number">
            <span>用水量 (吨)</span>
          </div>
          <div class="cct-header-price">
            <span>单价 (元)</span>
          </div>
          <div class="cct-header-money">
            <span>金额 (元)</span>
          </div>
        </div>
      </div>
      <div v-if="item.ladderList.length" class="cost-row">
        <div class="cost-row-column" v-for="(list, listIndex) in item.ladderList" :key="listIndex">
          <div class="cost-row-column-name">
            <span>{{ '第' + list.plLevel  + '阶梯'}}</span>
          </div>
          <div class="cost-row-column-number">
            <span>{{ list.billWaterLadder }}</span>
          </div>
          <div class="cost-row-column-price">
            <span>{{ list.plUnit }}</span>
          </div>
          <div class="cost-row-column-money">
            <span>{{ list.ladderAmount }}</span>
          </div>
        </div>
      </div>
      <div v-else class="cost-row">
        <div class="cost-row-column">
          <div class="cost-row-column-name">
            <!-- <span>{{ item.priceItemName }}</span> -->
          </div>
          <div class="cost-row-column-number">
            <span>{{ item.billWater }}</span>
          </div>
          <div class="cost-row-column-price">
            <span>{{ item.priceUnit }}</span>
          </div>
          <div class="cost-row-column-money">
            <span>{{ item.detailAmount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    waterPriceList: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    waterPriceList: {
      handler: function() {
        console.log(111, this.waterPriceList);
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="less" scoped>
.cost {
  // background-color: #ffffff;
  // margin-top: 16px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    background-color: #ffffff;
    margin: 10px 0px;
    .cost-left-name {
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 16px;
      line-height: 20px;
      font-weight: 700;
    }
    .cost-left-data {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      line-height: 20px;
    }
    .cost-right-money {
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 700;
      line-height: 20px;
    }
  }
  &-content {
    background: #f7faff;
    position: relative;
    .cct-header {
      display: flex;
      padding: 10px 16px;
      &-name {
        flex: 1;
        display: flex;
        flex-direction: column;
        .ccth-name {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
        }
        .ccth-describe {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
      &-number {
        width: 22%;
        display: flex;
        align-items: center;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
      &-price {
        width: 22%;
        display: flex;
        align-items: center;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
      &-money {
        width: 22%;
        display: flex;
        align-items: center;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
  .cost-content::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0px;
    top: 0;
    left: 0px;
    border-top: 1px solid #dadada;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  &-row {
    background: #ffffff;
    &-column {
      display: flex;
      padding: 10px 16px;
      &-name {
        flex: 1;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
        em {
          font-style: normal;
        }
      }
      &-number {
        display: flex;
        align-items: center;
        width: 22%;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
      &-price {
        display: flex;
        align-items: center;
        width: 22%;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
      &-money {
        display: flex;
        align-items: center;
        width: 22%;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}
</style>
