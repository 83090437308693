<template>
  <div v-if="dataInfo" class="fee-pay">
    <div class="fee-haed">
      <div class="fee-haed-name">抄表水费</div>
      <div class="fee-haed-money">
        <span class="fhm-unit">¥</span>
        <span class="fhm-amount">{{ isActual(dataInfo.waterFee) }}</span>
      </div>
    </div>
    <div class="fee-content">
      <div class="fee-content-user">
        <user-info :dataInfoObj="dataInfo" />
      </div>
      <div v-if="dataInfo.priceMixList && dataInfo.priceMixList.length" class="fee-content-price">
        <div class="cost-content">
          <div class="cct-header">
            <div class="cct-header-price">
              <span>用水性质</span>
            </div>
            <div class="cct-header-money">
              <span>用水量 (吨)</span>
            </div>
          </div>
        </div>
        <div class="cost-row" v-for="(list, listIndex) in dataInfo.priceMixList" :key="listIndex">
          <div class="cost-row-column">
            <div class="cost-row-column-price">
              <span>{{ list.wpName }}</span>
            </div>
            <div class="cost-row-column-money">
              <span>{{ list.chargeCount }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="fee-content-cost">
        <div class="cost-header">
          <div>
            <span class="cost-left-name">违约金</span>
            <span v-if="dataInfo.penaltyBeginDay" class="cost-left-data">
              {{ penaltyDay(dataInfo.penaltyBeginDay) }}起算
            </span>
          </div>
          <div>
            <span class="cost-right-money">{{ isActual(dataInfo.penalty) }}元</span>
          </div>
        </div>
        <cost-details
          v-if="dataInfo.detailList && dataInfo.detailList.length"
          :waterPriceList="dataInfo"
        />
      </div>
    </div>
    <div class="fee-foot">
      <!-- 未缴账单详情中显示 -->
      <van-cell center v-if="!isPaidFlag">
        <template #title>
<!--          <div class="fee-foot-mouth">还需支付：¥{{ isActual(totalStillNeedToPay) }}</div>-->
<!--          <div v-if="dataInfo.depositAmount" class="fee-foot-name">-->
<!--            <van-checkbox-->
<!--              v-model="dataInfo.depositType"-->
<!--              shape="square"-->
<!--              icon-size="15px"-->
<!--              :disabled="dataInfo.isPrestore"-->
<!--              @click="getNeedToPay"-->
<!--            >-->
<!--              预存可抵：¥{{ isActual(dataInfo.depositAmount) }}-->
<!--            </van-checkbox>-->
<!--          </div>-->
        </template>
        <template #default>
<!--          <div>-->
<!--            <van-button class="fee-foot-pay" type="info" @click="clickPaymentOrder">-->
<!--              立即缴费-->
<!--            </van-button>-->
<!--          </div>-->
        </template>
      </van-cell>
      <!-- 已缴账单详情中显示 -->
      <van-cell center v-else>
        <template #title>
<!--          <div class="fee-foot-mouth" v-if="dataInfo.netReceipts">-->
<!--            已支付：¥{{ isActual(dataInfo.netReceipts) }}元-->
<!--          </div>-->
        </template>
      </van-cell>
    </div>

    <!-- 发票成功-弹出框 -->
    <invoice-popup :showFlag="show" @clickPopup="closePopup" />
  </div>
</template>

<script>
import moment from 'moment';
import UserInfo from './module/userInfo';
import CostDetails from './module/costDetails';
import InvoicePopup from '../Invoice/module/invoicePopup';
import { formatTime } from '@/utils/tools';
import { selectByBill, paymentOrder, selectBillByThird, invoice } from '../../api/home';
import { Dialog, Toast } from 'vant';
import NP from 'number-precision';
import { isAmount } from '@/utils/validator';

export default {
  components: {
    UserInfo,
    CostDetails,
    InvoicePopup
  },
  data() {
    return {
      checked: '',
      waterPriceList: undefined,
      billId: this.$route.query.billId,
      wuserNo: this.$route.query.wuserNo,
      dataInfo: null,
      totalStillNeedToPay: 0,
      isPaidFlag: false,
      buttonContent: '电子发票',
      tabIndex: 0,
      show: false // 电子发票
    };
  },
  computed: {
    penaltyDay() {
      return (val) => {
        return moment(val).format('YYYY年MM月DD日');
      };
    },
    isActual() {
      return (val) => {
        return isAmount(val);
      };
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.name === 'invoice') {
      document.title = '已缴账单详情';
      this.isPaidFlag = true;
    }
    this.selectByBill();
  },
  methods: {
    async selectByBill() {
      let arg = {
        billId: this.billId,
        wuserNo: this.wuserNo
      };
      const { status, resultData } = await selectByBill(arg);
      if (status === 'complete') {
        this.waterPriceList = resultData.detailList;
        resultData.finalPaymentDate = formatTime(resultData.finalPaymentDate);
        // 判断余额是否大于0
        if (!isNaN(Number(resultData.depositAmount)) && Number(resultData.depositAmount) > 0) {
          resultData.isPrestore = false;
          resultData.depositType = true;
        } else {
          resultData.isPrestore = true;
          resultData.depositType = false;
        }
        // 还需支付：
        if (resultData.depositType && !isNaN(Number(resultData.depositAmount))) {
          let actual = NP.minus(Number(resultData.waterFee), Number(resultData.depositAmount));
          if (actual > 0) {
            this.totalStillNeedToPay = actual;
          } else {
            this.totalStillNeedToPay = 0;
          }
        } else {
          this.totalStillNeedToPay = resultData.waterFee;
        }
        this.dataInfo = resultData;
      }
    },
    // 已缴账单详情
    async selectBillByThird() {
      let arg = {
        wuserNo: this.wuserNo
      };
      const { status, resultData } = await selectBillByThird(arg);
      if (status === 'complete') {
        resultData.finalPaymentDate = formatTime(resultData.finalPaymentDate);
        this.dataInfo = resultData;
        this.waterPriceList = resultData.detailList;
      }
    },
    // 计算点击预存可扣的事件 【还需支付 - 预存可扣 = 还需支付的】【支付金额 < 余额 调接口】
    async getNeedToPay() {
      if (this.dataInfo.depositType && !isNaN(Number(this.dataInfo.depositAmount))) {
        let actual = NP.minus(
          Number(this.totalStillNeedToPay),
          Number(this.dataInfo.depositAmount)
        );
        if (actual > 0) {
          this.totalStillNeedToPay = actual;
        } else {
          this.totalStillNeedToPay = 0;
        }
      } else {
        this.totalStillNeedToPay = this.dataInfo.waterFee;
      }
    },
    // 一键缴费
    async clickPaymentOrder() {
      if (!isNaN(Number(this.totalStillNeedToPay)) && Number(this.totalStillNeedToPay) <= 0) {
        Dialog.alert({
          title: '无需支付',
          message: '余额大于缴费金额，会自动抵扣!',
          confirmButtonColor: '#277FF2'
        });
        return;
      }
      let arg = [
        {
          wuserNo: this.dataInfo.wuserNo,
          depositType: this.dataInfo.depositType,
          billId: [this.dataInfo.billId]
        }
      ];
      const { status, resultData } = await paymentOrder(arg);
      if (status === 'complete') {
        if (resultData) {
          if (Object.keys(resultData).length === 0) {
            if (typeof WeixinJSBridge === 'undefined') {
              if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
              } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
              }
            }
          } else {
            this.onBridgeReady(resultData);
          }
        } else {
          this.$router.push({
            name: 'PaymentResult',
            path: '/QueryPay/PaymentResult',
            query: { name: 'QueryPay' }
          });
        }
      }
    },
    // 微信支付：
    onBridgeReady(params) {
      var that = this;
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: params.appId, //公众号名称，由商户传入
          timeStamp: params.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: params.nonceStr, //支付签名随机串，不长于 32 位
          package: params.packageValue, //统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: params.signType, //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: params.paySign //支付签名
        },
        function(res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            that.$router.push({
              name: 'PaymentResult',
              path: '/QueryPay/PaymentResult',
              query: {
                name: 'QueryPay'
              }
            });
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            console.log('失败', res.err_msg);
          }
        }
      );
    },

    // 发票弹框--关闭成功页面
    closePopup() {
      this.buttonContent = '查看发票';
      this.tabIndex = 1;
      this.show = false;
    },
    // 电子发票
    async gotoInvoicePage(tabIndex) {
      // 电子发票
      if (tabIndex === 0) {
        this.buttonContent = '开票中...';
        const arg = [
          {
            wuserNo: this.dataInfo.wuserNo,
            custRealId: this.$route.query && this.$route.query.custRealId,
            electronicInvoicesId: this.$route.query && this.$route.query.electronicInvoicesId
          }
        ];
        const { status, resultData } = await invoice(arg);
        if (status === 'complete') {
          this.show = true;
        }
      } else {
        // 查看发票
        Toast('暂未开发');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.fee-pay {
  position: relative;
  .fee-haed {
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow: hidden;
    background: url('../../assets/background/backdrop.png');
    background-size: 100% auto;
    &-name {
      // padding: 19px 0 16px 16px;
      margin: 16px;
      font-family: PingFangSC-Medium;
      font-size: 16px;
      color: #ffffff;
    }
    &-money {
      font-size: 38px;
      color: #ffffff;
      display: flex;
      align-items: center;
      margin: 0 16px;
      .fhm-unit {
        font-family: PingFangSC-Semibold;
        margin-right: 10px;
      }
      .fhm-amount {
        font-family: DINAlternate-Bold;
      }
    }
    // &-trem {
    //   display: flex;
    //   align-items: center;
    //   margin: 0 16px;
    //   color: #c2d9ff;
    //   font-size: 14px;
    //   padding-top: 6px;
    //   &-data {
    //     margin-left: 6px;
    //     font-size: 14px;
    //     font-family: PingFangSC-Regular;
    //   }
    // }
  }
  .fee-content {
    margin: -190px 0px;
    border-radius: 8px;
    padding-bottom: 80px;
    &-user {
      margin: 0 16px;
      border-radius: 8px;
      margin-bottom: 10px;
    }
    &-cost {
      .cost-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px;
        background-color: #ffffff;
        margin: 10px 0px;
        .cost-left-name {
          font-family: PingFangSC-Medium;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          margin-right: 16px;
          line-height: 20px;
          font-weight: 700;
        }
        .cost-left-data {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 20px;
        }
        .cost-right-money {
          font-family: PingFangSC-Medium;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 700;
          line-height: 20px;
        }
      }
    }
    .fee-content-price {
      margin: 0 16px;
      border-radius: 8px;
      margin-bottom: 10px;
      background: #fff;
      overflow: hidden;

      .cost-content {
        position: relative;
        .cct-header {
          display: flex;
          padding: 10px 16px;
          &-price {
            width: 50%;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
          }
          &-money {
            width: 50%;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }
      .cost-row {
        background: #ffffff;
        border-top: 1px solid #f0f0f0;
        &-column {
          display: flex;
          padding: 10px 16px;
          &-price {
            display: flex;
            align-items: center;
            width: 50%;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
          }
          &-money {
            display: flex;
            align-items: center;
            width: 50%;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }
}
.fee-foot {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  &-mouth {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #23282d;
    letter-spacing: -0.42px;
  }
  /deep/ .fee-foot-name .van-checkbox__label {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
  &-pay {
    background: #0a79ec;
    border-radius: 8px;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: -0.42px;
    // width: 88px;
    height: 40px;
  }
}
</style>
