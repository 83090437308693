<template>
  <div class="info">
    <div class="info-user">
      <div class="info-user-name">
        <div>{{ dataInfo.wuserName }}</div>
        <div>户号：{{ dataInfo.wuserNo }}</div>
      </div>
      <div class="info-user-state" v-if="dataInfo.wuserTypeName">{{ dataInfo.wuserTypeName }}</div>
      <div class="info-user-address van-multi-ellipsis--l3">
        <em
          class="info-customer-label"
          v-if="dataInfo.labelName && dataInfo.labelName.length > 0"
          >{{ dataInfo.labelName }}</em
        >
        <span class="info-customer-news">{{ dataInfo.wuserAddress }}</span>
      </div>
    </div>

    <div class="info-bill">
      <van-row>
        <van-col span="12" v-if="dataInfo.wuserNo">
          <div class="info-bill-label">
            <label class="ibl-name ">用户编号</label>
            <span class="ibl-value">{{ dataInfo.meterCode }}</span>
          </div>
        </van-col>
        <van-col span="12" v-if="dataInfo.meterList">
          <div class="info-bill-label">
            <label class="ibl-name rightWidth">表册号</label>
            <span class="ibl-value">{{ dataInfo.meterList }}</span>
          </div>
        </van-col>
        <van-col span="12" v-if="dataInfo.thisMonthMeter">
          <div class="info-bill-label">
            <label class="ibl-name">本月抄见</label>
            <span class="ibl-value">{{ dataInfo.thisMonthMeter }}</span>
          </div>
        </van-col>
        <van-col span="12" v-if="dataInfo.waterQuantity">
          <div class="info-bill-label">
            <label class="ibl-name rightWidth">用水量</label>
            <span class="ibl-value">{{ dataInfo.waterQuantity }}吨</span>
          </div>
        </van-col>
        <van-col span="12" v-if="dataInfo.meterReadName">
          <div class="info-bill-label">
            <label class="ibl-name">抄表员</label>
            <span class="ibl-value">{{ dataInfo.meterReadName }}</span>
          </div>
        </van-col>
        <van-col span="12" v-if="dataInfo.meterReadPhone">
          <div class="info-bill-label">
            <label class="ibl-name rightWidth">手机号</label>
            <span class="ibl-value">{{ dataInfo.meterReadPhone }}</span>
          </div>
        </van-col>
        <van-col :span="dataInfo.payDate ? 12 : 24" v-if="dataInfo.bookDate">
          <div class="info-bill-label">
            <label class="ibl-name">开账日期</label>
            <span class="ibl-value">{{ dataInfo.bookDate }}</span>
          </div>
        </van-col>
        <!-- 已缴账单详情中显示 -->
        <van-col span="12" v-if="dataInfo.payDate">
          <div class="info-bill-label">
            <label class="ibl-name">缴费日期</label>
            <span class="ibl-value">{{ dataInfo.payDate }}</span>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataInfoObj: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      dataInfo: {}
    };
  },
  mounted() {},
  methods: {},
  watch: {
    dataInfoObj: {
      immediate: true,
      handler(val) {
        this.dataInfo = val;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.info {
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 6px 16px 0 rgba(63, 70, 79, 0.12);
  &-user {
    // display: flex;
    // flex-wrap: wrap;
    display: block;
    align-items: center;
    padding: 16px;
    position: relative;
    &-name {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 700;

      :first-child {
        margin-right: 8px;
      }
    }
    &-state {
      position: absolute;
      top: 0;
      right: 0px;
      padding: 1px 6px;
      background: #f64357;
      border-radius: 0 8px 0 8px;
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #ffffff;
      letter-spacing: -0.3px;
    }
    &-address {
      padding-top: 3px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #000000;
      em {
        font-style: normal;
        background: #ecf1fe;
        border-radius: 2px;
        border-radius: 2px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #637fb7;
        letter-spacing: 0;
        line-height: 16px;
        padding: 1px 5px;
        margin-right: 8px;
      }
    }
  }

  .info-bill {
    padding: 0 16px 16px 16px;
    &-label {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      .ibl-name {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        letter-spacing: -0.42px;
      }
      .ibl-value {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: -0.42px;
      }
      label {
        width: 65px;
      }
      .rightWidth {
        width: 45px;
      }
      span {
        display: flex;
        flex: 1;
      }
    }
  }
}
</style>
